<template>
    <div class="container has-space" v-if="playerInfo.statisticData">
        <!-- <div class="nav-pill__wrapper mb-2rem">
            <div class="nav-pill selected">Premier League</div>
        </div> -->
        <div class="player__overview-card " v-for="(o, index) in playerInfo.statisticData" :key="index">
            <Navigation :id="o.id" :category="'team'" :name="o.name"  class="player__overview-header" >
                <Icon :logoUrl=o.logo :category="'team'"  class="player__overview-header__img"></Icon>               
                <div class="player__overview-header__title">
                    {{$t(o.name)}}
                </div>
            </Navigation>
            <div class="player__overview-header__match">
                <span>{{$t('SEASON')}}</span>
                <span class="mlr-03rem">{{o.season}}</span>
                <span>{{$t(o.league)}}</span>
            </div>
            <div class="player__overview-body">
                <div class="">{{$t('MINUTE')}}</div>
                <div class="">{{o.minutes === null || o.minutes === '' ? '-':o.minutes}}</div>
            </div>
            <div class="player__overview-body">
                <div class="">{{$t('LINEUPS')}}</div>
                <div class="">{{o.lineups === null || o.lineups === '' ? '-':o.lineups}}</div>
            </div>
            <div class="player__overview-body">
                <div class="">{{$t('APPEARENCES')}}</div>
                <div class="">{{o.appearences === null || o.appearences === '' ? '-':o.appearences}}</div>
            </div>
            <div class="player__overview-body">
                <div class="">{{$t('SUBSTITUTE_IN')}}</div>
                <div class="">{{o.substitute_in === null || o.substitute_in === '' ? '-':o.substitute_in}}</div>
            </div>
            <div class="player__overview-body">
                <div class="">{{$t('GOALS')}}</div>
                <div class="">{{o.goals === null || o.goals === '' ? '-':o.goals}}</div>
            </div>

            <div class="player__overview-body__card">{{$t('CARD')}}</div>
            <div class="player__overview-body"  >              
                <div class="">{{$t('YELLOW_CARDS')}}</div>
                <div class="">{{o.yellowcards === null || o.yellowcards === '' ? '-':o.yellowcards}}</div>
            </div>
            <div class="player__overview-body"  >              
                <div class="">{{$t('YELLOW_RED_CARDS')}}</div>
                <div class="">{{o.yellowred === null || o.yellowred === '' ? '-':o.yellowred}}</div>
            </div>
        
            <div class="player__overview-body"  >              
                <div class="">{{$t('RED_CARDS')}}</div>
                <div class="">{{o.redcards === null || o.redcards === '' ? '-':o.redcards}}</div>
            </div>
        </div> 
    </div>
    <div class="no-data" v-if="!playerInfo.statisticData">{{$t('NO_CAREER')}}</div>
   
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
export default {
    data() {
        return {
        }
    },
    computed: {
    ...mapGetters([
        'playerInfo',
        'isMobile',
        'currentLocale',
        'currentSportType'
    ]),
},
}
</script>

<style>
    .player__overview-card{
        margin-bottom: 1rem;
    }
    .player__overview-header{
        display:flex;
        align-items: center;
        margin-bottom: .5rem;
        font-size:.85rem
    }
     .player__overview-header:hover .player__overview-header__title{
         color: var(--color-hover)
    }
    .player__overview-header__img{
        width: 1.5rem;
        margin-right: .5rem;
    }
    .player__overview-header__img img{
        width:100%
    }
    .player__overview-header__title{
        font-weight: 700;
        color:white
    }

    .player__overview-header__match{
        margin-bottom: 1rem;
        font-size: .88rem;
    }
    .player__overview-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: .7rem;
        padding: .5rem 0;
        border-bottom: .1rem solid #212B30;
        color: var(--color-grey-light);
    }
    .player__overview-body__card{
        margin: 1rem 0;
        font-size:.95rem
    }
</style>